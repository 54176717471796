<template>
  <div class="pay-success-container">
    <div class="pay-card" v-if="isShow">
      <div class="card-top">
        <p class="youhao">{{homeData.orderType}}{{ homeData.youhao }}</p>
        <p class="pay-total" v-if="false">￥{{ homeData.money }}</p>
      </div>
      <div class="pay-content">
        <img src="@/assets/img/6.png" alt="" srcset="">
        <p class="pay-real">￥{{homeData.finalMoney}}</p>
        <p class="time">{{homeData.orderTime}}</p>
<!--        <p style="font-size: 20px">会员账号:{{homeData.phone}}</p>-->
      </div>
      <div class="pay-bottom" v-if="false">
        <ul>
          <li class="top">
            <span style="color: gray">数量</span>
            <span>{{homeData.rise}}{{unit}}</span>
          </li>
          <li class="bottom">
            <span style="color: gray">优惠金额</span>
            <span>￥{{homeData.discountMoney}}</span>
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>



export default {
  name: "paySuccess",
  data() {
    return {
      isShow:true,
      iconSrc: require("@/assets/img/8.png"),
      pay: "",
      unit:"L",
      homeData: {
        youhao: "",
        orderType: "",
        money: "",//总付款
        finalMoney: "",//实付款
        orderTime: "",//付款时间
        rise: "",//付款数量
        discountMoney: "",//优惠金额
        phone:"",

      },
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    payFail(orderId){
      this.$router.push({
        name: "payFail",
        path: "payFail",
        query: {
          orderId: orderId,//传给支付失败页面
        }
      })
    },
    async getData() {
      let query =  this.$route.query;
      if(query.result==='cancel'){
      //    显示取消支付页面
        this.isShow = false;
        this.payFail(query.outOrderNo);
        return;
      }
      this.orderId = query.outOrderNo;//获取加油付款页面生成的orderid
      const res = await this.$get("/jsapi/get_order_info", {
        orderId: this.orderId,
      });

      if (res.code === 200) {
        this.homeData = {
          money: res.data.money,
          youhao: res.data.youhao,
          finalMoney: res.data.finalMoney,
          orderTime: res.data.orderTime,
          rise: res.data.rise,
          discountMoney: res.data.discountMoney,
          phone: res.data.phone,

        };
        if(res.data.orderType === 0){
          this.homeData.orderType = "汽油"
        }
        if(res.data.orderType === 1){
          this.homeData.orderType = "CNG天然气"
          this.unit = "m³";
        }
        if(res.data.orderType === 2){
          this.homeData.orderType = "柴油"
        }
      }
    },
  },

}
</script>

<style scoped lang="less">
p{
  margin:0;
}
.pay-success-container {
  .pay-card {
    width: 90%;
    margin: 20px auto;
    background: #fff;

    .card-top {
      background: #2380ff;
      color: #fff;

      p {
        text-align: center;
      }

      .youhao {
        font-size: 30px;
        padding: 20px 0 10px 0;
      }

      .pay-total {
        font-size: 26px;
        padding-bottom: 15px;
      }
    }

    .pay-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 15px;

      img {
        width: 70px;
        height: 70px;
        object-fit: cover;
      }

      .pay-real {
        color: #56ad53;
        font-size: 22px;
        font-weight: 600;
      }

      .time {
        font-size: 18px;
        padding: 20px 0;
      }
    }

    .pay-bottom {
      margin-top: 20px;
      padding: 0 20px 10px 20px;

      li {
        display: flex;
        justify-content: space-between;
        padding: 5px 0;
      }
    }
  }
}

//导航栏返回按钮颜色
/deep/ .van-nav-bar .van-icon {
  color: #000;
}
</style>